import axios from 'axios'
import authHeader from '../authHeader'

const state = {
  contactDetails: {}
}

const getters = {}

const actions = {
  getContactDetails: async ({ commit }) => {
    try {
      const res = await axios.get(`/api/loyalty/contact`,
        { headers: authHeader() }
      )
      commit('updateContactDetails', res.data)
    } catch (error) {
      console.log(error)
    }
  }
}

const mutations = {
  updateContactDetails: (state, payload) => {
    state.contactDetails = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
