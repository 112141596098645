import axios from 'axios'
import authHeader from '../authHeader'

const state = {
  periods: []
}

const getters = {
  getActivePeriodId({ periods }) {
    return periods.find(period => period.isActive).id || null
  }
}

const actions = {
  getPeriods: async ({ commit }) => {
    try {
      const res = await axios.get(`/api/loyalty/period`, { headers: authHeader() })
      commit('updatePeriods', res.data.periods)
    } catch (error) {
      console.log(error)
    }
  }
}

const mutations = {
  updatePeriods: (state, payload) => {
    state.periods = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
