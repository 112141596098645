import axios from 'axios';
import router from '../../router';
import jwt_decode from 'jwt-decode';
import i18n from '../../i18n';
import authHeader from '../authHeader';

const state = {
	idToken: null,
	user: null,
};

const mutations = {
	authUser(state, userData) {
		state.idToken = userData.token;
	},
	storeUser(state, user) {
		state.user = user;
	},
	clearAuthData(state) {
		state.idToken = null;
	},
};

const actions = {
	setLogoutTimer({ dispatch }, expirationTime) {
		const now = new Date().getTime();
		const formattedExpTime = new Date(expirationTime * 1000).getTime();
		const logoutInterval = formattedExpTime - now;
		setTimeout(() => {
			dispatch('logout');
		}, logoutInterval);
	},
	async login({ commit, dispatch }, authData) {
		try {
			const res = await axios.post('/loyalty/login', {
				username: authData.username,
				password: authData.password,
				appCode: authData.appCode,
			});
			const tokenToDecode = res.data.token;
			const decodedToken = jwt_decode(tokenToDecode);
			const expirationDate = new Date(decodedToken.exp * 1000).getTime();
			localStorage.setItem('token', res.data.token);
			localStorage.setItem('expirationDate', expirationDate.toString());
			commit('authUser', {
				token: res.data.token,
			});
			commit('storeUser', {
				username: res.data.username,
			});
			const agreementsRes = await axios.get('/api/loyalty/agreement', { headers: authHeader() });

			//find in all agreements one with no accepted required agreement and then put negative value
			const areRequiredAgreementsAccepted = !agreementsRes.data.agreements.some((agreement) => agreement.isRequired && !agreement.isAccepted);

			localStorage.setItem('areRequiredAgreementsAccepted', areRequiredAgreementsAccepted);

			dispatch('setLogoutTimer', decodedToken.exp);
			if (areRequiredAgreementsAccepted) {
				router.replace(`/${i18n.locale}/`);
			}
			if (!areRequiredAgreementsAccepted) {
				router.replace(`/${i18n.locale}/agreements`);
			}
		} catch (error) {
			const errorMessage = (error.response.data && error.response.data.message) || error;
			dispatch('alert/error', errorMessage, { root: true });
		}
	},
	tryAutoLogin({ commit }) {
		const token = localStorage.getItem('token');

		if (!token) {
			return;
		}
		const expirationTime = Number(localStorage.getItem('expirationDate'));
		const now = new Date().getTime();
		if (now >= expirationTime || !expirationTime) {
			//there should be placed refresh token action
			return;
		}
		commit('authUser', {
			token: token,
		});
	},
	logout({ commit }) {
		commit('clearAuthData');
		commit('alert/clear', null, { root: true });
		localStorage.removeItem('expirationDate');
		localStorage.removeItem('token');
		localStorage.removeItem('areRequiredAgreementsAccepted');
		router.replace(`/${i18n.locale}/login`);
	},
};

const getters = {
	user(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.idToken !== null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
