<template>
	<div class="rules">
		<div>
			<div class="row">
				<div class="col-12">
					<h1 class="color-red page-titles mt-4">{{ $t('pages.rules.paragraph1') }}</h1>
				</div>

				<div class="col-12 rules-image-container rules-image-container-desktop">
					<img src="@/assets/images/rules2.png" srcset="@/assets/images/rules2.png 1x, @/assets/images/rules2@2x.png 2x" alt v-if="$i18n.locale === 'pl'" />
					<img src="@/assets/images/rules2-en.png" srcset="@/assets/images/rules2-en.png 1x, @/assets/images/rules2-en@2x.png 2x" alt v-else />
				</div>
				<div class="col-12 rules-image-container rules-image-container-mobile">
					<div class="rules-additional-info-mobile my-4" v-html="$t('pages.rules.topMobileInfo')"></div>
					<img src="@/assets/images/rules2-mobile-1.png" srcset="@/assets/images/rules2-mobile-1.png 1x, @/assets/images/rules2-mobile-1@2x.png 2x" alt />
					<div class="rules-additional-info-mobile my-4" v-html="$t('pages.rules.additionalInfo')"></div>
					<img src="@/assets/images/rules2-mobile-2.png" srcset="@/assets/images/rules2-mobile-2.png 1x, @/assets/images/rules2-mobile-2@2x.png 2x" alt />
				</div>
				<div class="col-12 mt-4">
					<div class="rules-titles_container--1">
						<span class="rules-titles_txt" v-html="$t('pages.rules.paragraph2')"></span>
					</div>
				</div>
				<div class="col-12 rules-image-container rules-image-container-desktop mb-4">
					<img src="@/assets/images/rules1.png" srcset="@/assets/images/rules1.png 1x, @/assets/images/rules1@2x.png 2x" alt v-if="$i18n.locale === 'pl'" />
					<img src="@/assets/images/rules1-en.png" srcset="@/assets/images/rules1-en.png 1x, @/assets/images/rules1-en@2x.png 2x" alt v-else />
				</div>
				<div class="col-12 rules-image-container rules-image-container-mobile mb-4">
					<img src="@/assets/images/rules1-mobile.png" srcset="@/assets/images/rules1-mobile.png 1x, @/assets/images/rules1-mobile@2x.png 2x" alt v-if="$i18n.locale === 'pl'" />
					<img src="@/assets/images/rules1-mobile-en.png" srcset="@/assets/images/rules1-mobile-en.png 1x, @/assets/images/rules1-mobile-en@2x.png 2x" alt v-else />
				</div>
			</div>
			<ImageModal v-if="storeDetails.countryCode === 'GB'" />
			<CustomModal />
		</div>
	</div>
</template>
<script>
import CustomModal from '../components/CustomModal.vue';
import ImageModal from '../components/ImageModal.vue';
import { mapActions, mapState } from 'vuex';
export default {
	name: 'rules',
	components: {
		CustomModal,
		ImageModal,
	},
	async created() {
		try {
			await this.getStoreDetails();
		} catch (error) {
			console.log(error);
		}
	},
	computed: {
		...mapState({
			storeDetails: (state) => state.main.storeDetails,
		}),
	},
	methods: {
		...mapActions('main', {
			getStoreDetails: 'getStoreDetails',
		}),
	},
};
</script>
