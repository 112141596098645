<template>
	<div class="catalog">
		<div v-if="isLoading">
			<Preloader />
		</div>
		<div v-if="!isLoading">
			<section class="catalog-section">
				<div class="row mb-4">
					<div class="col-12">
						<h1 class="page-titles mt-4">{{ $t('pages.catalog.title') }}</h1>
					</div>
				</div>
				<div class="row">
					<div class="col-12 catalog-header">
						<!-- {{ $t('pages.catalog.catalogDateHeader') }}: <span class="font-weight-bold text-nowrap">{{ catalogDateFrom }} - {{ catalogDateTo }}</span> -->
						{{ $t('pages.catalog.catalogDateHeader') }}: <span class="font-weight-bold text-nowrap">29.10.2024 - 25.11.2024</span>
					</div>
				</div>
				<div class="row">
					<div class="col-12 flipbook-handler">
						<flipbook class="flipbook flipbook-gazetka" :pages="pages" :pagesHiRes="pagesHiRes" :zooms="zoomLevel" v-slot="flipbook">
							<div class="row flipbook-nav">
								<div class="flip-arrow-container left-arrow-anch" @click="flipbook.flipLeft">
									<img
										class="left-arrow arrow-blue"
										src="@/assets/icons/left-arrow.png"
										srcset="@/assets/icons/left-arrow.png 1x, @/assets/icons/left-arrow@2x.png 2x"
										alt
										:class="disabledLeft"
										@click="leftArrowClick"
									/>
								</div>
								<div class="flip-arrow-container right-arrow-anch" @click="flipbook.flipRight">
									<img
										class="right-arrow arrow-blue"
										src="@/assets/icons/right-arrow.png"
										srcset="@/assets/icons/right-arrow.png 1x, @/assets/icons/right-arrow@2x.png 2x"
										alt
										:class="disabledRight"
										@click="rightArrowClick"
									/>
								</div>
							</div>
						</flipbook>
					</div>
					<div class="col-12 download-link mb-5">
						<img class="mr-1" src="@/assets/icons/pdf-dark.png" srcset="@/assets/icons/pdf-dark.png 1x, @/assets/icons/pdf-dark@2x.png 2x" alt />
						<a :href="`/catalog/${catalogDetails.countryCode}/${periodId}/pdf/MasterMedia.pdf`" target="_blank">{{ $t('pages.catalog.downloadCatalogue') }}</a>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Flipbook from 'flipbook-vue';
import axios from 'axios';
import Preloader from './../components/Preloader.vue';

export default {
	name: 'catalog',
	components: {
		Flipbook,
		Preloader,
	},
	data() {
		return {
			isLoading: true,
			page: 0,
			pages: [],
			pagesHiRes: [],
			zoomLevel: [1],
			folder: '',
			catalogDateFrom: '',
			catalogDateTo: '',
			disabledLeft: '',
			disabledRight: '',
			periodId: 10,
			// catalogFilesAddress: 'https://moneymasters24.tcreate.pl',
			catalogFilesAddress: 'https://moneymasters.fun',
		};
	},
	async created() {
		try {
			this.isLoading = true;
			await this.getCatalogDetails();

			// this.folder = this.selectCountryFolder(this.catalogDetails.countryCode) + '/' + this.catalogDetails.periodId;
			this.folder = this.selectCountryFolder(this.catalogDetails.countryCode) + '/' + this.periodId;

			await axios.get(`${this.catalogFilesAddress}/pages.php?folder=${this.folder}`).then((response) => {
				this.pages.push(null);
				this.pagesHiRes.push(null);
				for (let index = 0; index < response.data.pages; index++) {
					this.pages.push(`${this.catalogFilesAddress}/catalog/${this.folder}/thumbs/${index + 1}.jpg`);
					this.pagesHiRes.push(`${this.catalogFilesAddress}/catalog/${this.folder}/${index + 1}.jpg`);
				}
			});
			this.isLoading = false;
			this.catalogDateFrom = this.changeDateFormat(this.catalogDetails.catalogPeriodValidFrom);
			this.catalogDateTo = this.changeDateFormat(this.catalogDetails.catalogPeriodValidTo);
		} catch (error) {
			console.log(error);
		}
	},
	computed: {
		...mapState({
			catalogDetails: (state) => state.catalog.catalogDetails,
		}),
	},
	methods: {
		...mapActions('catalog', {
			getCatalogDetails: 'getCatalogDetails',
		}),

		thousands_separators(num) {
			if (num != null) {
				let num_parts = num.toString().split('.');
				num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
				return num_parts.join('.');
			}
			if (num == null) return;
		},

		changeDateFormat(dateToFormat) {
			const passedDate = new Date(dateToFormat);

			let dd = passedDate.getDate(); // day
			let mm = passedDate.getMonth() + 1; // month
			let yyyy = passedDate.getFullYear(); // year
			if (dd <= 9) dd = '0' + dd;
			if (mm <= 9) mm = '0' + mm;

			// return formatted date
			return dd + '.' + mm + '.' + yyyy;
		},
		leftArrowClick() {
			document.querySelector('.left-arrow-anch').click();
		},
		rightArrowClick() {
			return document.querySelector('.right-arrow-anch').click();
		},
		disableLeftFn(pagesValue) {
			if (pagesValue == 1) {
				this.disabledLeft = 'arrow-disabled';
				return 'arrow-disabled';
			} else {
				this.disabledLeft = '';
				return '';
			}
		},
		disableRightFn(numPagesValue, pagesValue) {
			if (numPagesValue == pagesValue) {
				this.disabledRight = 'arrow-disabled';
				return 'arrow-disabled';
			} else {
				this.disabledRight = '';
				return '';
			}
		},
		selectCountryFolder(country) {
			let folderCode = '';
			switch (country) {
				case 'BE':
					folderCode = 'BE';
					break;
				case 'DE':
					folderCode = 'DE';
					break;
				case 'FR':
					folderCode = 'EU';
					break;
				case 'LU':
					folderCode = 'EU';
					break;
				case 'NL':
					folderCode = 'NL';
					break;
				case 'GB':
					folderCode = 'GB';
					break;
				case 'IE':
					folderCode = 'IE';
					break;
				case 'AT':
					folderCode = 'EU';
					break;
				case 'XI':
					folderCode = 'GB';
					break;

				//temporary cases
				case 'IRL':
					folderCode = 'IE';
					break;
				case 'UK':
					folderCode = 'GB';
					break;

				default:
					folderCode = 'none';
			}
			return folderCode;
		},
	},
};
</script>
