import axios from 'axios';
import authHeader from '../authHeader';

const state = {
	result: {},
	totalCashback: {},
};

const getters = {};

const actions = {
	getResults: async ({ commit }, periodId) => {
		try {
			const res = await axios.post(`/api/loyalty/result`, { periodId: periodId, planTypeId: 1 }, { headers: authHeader() });
			commit('updateResults', res.data);
		} catch (error) {
			console.log(error);
		}
	},

	getTotalCashback: async ({ commit }) => {
		try {
			const res = await axios.get(`/api/loyalty/cashback`, { headers: authHeader() });
			commit('updateTotalCashback', res.data);
		} catch (error) {
			console.log(error);
		}
	},
};

const mutations = {
	updateResults: (state, payload) => {
		state.result = payload;
	},
	updateTotalCashback: (state, payload) => {
		state.totalCashback = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
