import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import Login from '../views/Login.vue';
import Error404 from '../views/Error404.vue';
import Agreements from '../views/Agreements.vue';
import Rules from '../views/Rules.vue';
import Catalog from '../views/Catalog.vue';
import Contact from '../views/Contact.vue';
import TotalCashback from '../views/TotalCashback.vue';
import i18n from '../i18n';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: `/:locale`,
	},
	{
		path: `/:locale`,
		component: {
			render(c) {
				return c('router-view');
			},
		},
		children: [
			{
				path: '/',
				name: 'Rules',
				component: Rules,
			},
			{
				path: 'login',
				name: 'Login',
				component: Login,
			},
			{
				path: 'agreements',
				name: 'Agreements',
				component: Agreements,
			},
			{
				path: 'results',
				name: 'Results',
				component: () => import('../views/Results.vue'),
			},
			{
				path: 'catalog',
				name: 'Catalog',
				component: Catalog,
			},
			{
				path: 'contact',
				name: 'Contact',
				component: Contact,
			},
			{
				path: 'total-cashback',
				name: 'TotalCashBack',
				component: TotalCashback,
			},
			{
				path: '*',
				name: 'Error404',
				component: Error404,
			},
		],
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: (to, from, savedPosition) => {
		if (savedPosition) {
			return savedPosition;
		} else if (to.hash) {
			return {
				selector: to.hash,
			};
		} else {
			return { x: 0, y: 0 };
		}
	},
});

router.beforeEach((to, from, next) => {
	let language = to.params.locale;
	if (!language) {
		language = localStorage.getItem('userLang') || 'pl';
	}

	i18n.locale = language;

	// redirect to login page if not logged in and trying to access a restricted page
	const publicPages = [`/${language}/login`];
	// const publicPages = [`/login`]
	const authRequired = !publicPages.includes(to.path);
	let loggedIn = store.state.auth.idToken;

	// after page refresh store data is lost, try auto login
	if (!loggedIn) {
		store.dispatch('auth/tryAutoLogin');
		loggedIn = store.state.auth.idToken;
	}

	if (to.path === `/${language}/login` && loggedIn) {
		return next(`/${language}/`);
	}
	if (authRequired && !loggedIn) {
		return next(`/${language}/login`);
	}

	const agreementsAccepted = localStorage.getItem('areRequiredAgreementsAccepted') === 'true';
	if (authRequired && !agreementsAccepted && to.path !== `/${language}/agreements`) {
		return next(`/${language}/agreements`);
	}

	if (agreementsAccepted && to.path === `/${language}/agreements`) {
		return next(`/${language}/`);
	}

	if (to.path === `/` && loggedIn) {
		return next(`/${language}/`);
	}

	next();
});

export default router;
