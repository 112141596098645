<template>
	<header id="header" :class="headerScrollClass">
		<div class="row navigation-row custom-container" v-if="!isLoading">
			<div class="col navigation-row_left">
				<b-navbar toggleable="xl">
					<router-link :to="`/${$i18n.locale}/`" class="logo-nav">
						<img src="@/assets/logos/logo.png" srcset="@/assets/logos/logo.png 1x, @/assets/logos/logo@2x.png 2x" alt class="navbar-logo" />
					</router-link>

					<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

					<b-collapse id="nav-collapse" is-nav>
						<b-navbar-nav class="navbar-items-container">
							<b-nav-item :to="`/${$i18n.locale}/`">{{ $t('nav.items.rules') }}</b-nav-item>
							<b-nav-item :to="`/${$i18n.locale}/results`">{{ $t('nav.items.results') }}</b-nav-item>
							<b-nav-item :to="`/${$i18n.locale}/catalog`">{{ $t('nav.items.catalog') }}</b-nav-item>
							<b-nav-item :to="`/${$i18n.locale}/contact`">{{ $t('nav.items.contact') }}</b-nav-item>
						</b-navbar-nav>
						<div class="mobile-languages-logout">
							<div class="mobile-languages-logout--items">
								<div class="mobile-languages-icons-container">
									<div class="mobile-languages-icons-container--store">
										<div class="store-container">{{ storeDetails.storeName }}</div>
									</div>
									<div class="mobile-languages-icons-container--buttons">
										<img
											class="language-buttons language-buttons_pl"
											src="@/assets/icons/pl.png"
											srcset="@/assets/icons/pl.png 1x, @/assets/icons/pl@2x.png 2x"
											alt
											@click="switchLocale('pl')"
											:class="$i18n.locale == 'en' ? 'language-buttons-inactive' : ''"
											v-b-toggle:nav-collapse
										/>
										<img
											class="language-buttons language-buttons_en ml-2"
											src="@/assets/icons/en.png"
											srcset="@/assets/icons/en.png 1x, @/assets/icons/en@2x.png 2x"
											alt
											@click="switchLocale('en')"
											:class="$i18n.locale == 'pl' ? 'language-buttons-inactive' : ''"
											v-b-toggle:nav-collapse
										/>
										<div class="logout-button-mobile font-weight-bold" @click="onLogout">
											{{ $t('nav.logoutMobile') }}
											<img class="logout-button" src="@/assets/icons/logout.png" srcset="@/assets/icons/logout.png 1x, @/assets/icons/logout@2x.png 2x" alt />
										</div>
									</div>
								</div>
							</div>
						</div>
					</b-collapse>
					<div class="navigation-row--data-mobile w-100">
						<div class="row row-data-items">
							<div class="col-4 data-items data-items_first">
								<div class="data-items--text px-3 w-100">
									<span v-html="$t('nav.yourCashBack')"></span>
									<span class="text-data color-red"> {{ storeDetails.currency === 'GBP' ? '£' : '' }} {{ storeDetails.cashback }} {{ storeDetails.currency === 'EUR' ? '€' : '' }} </span>
								</div>
							</div>
							<div class="col-4 data-items">
								<div class="data-items--text pl-0 w-100">
									<router-link :to="`/${$i18n.locale}/total-cashback`" class="total-cashback-nav-button" v-html="$t('nav.totalCashBack')"></router-link>
								</div>
							</div>
							<div class="col-4 data-items data-items_second">
								<div class="data-items--icon">
									<img src="@/assets/icons/time.png" srcset="@/assets/icons/time.png 1x, @/assets/icons/time@2x.png 2x" alt />
								</div>
								<div class="data-items--text">
									{{ $t('nav.endStageTime') }}
									<span class="text-data color-red">{{ storeDetails.daysTillPeriodEnd }} {{ daysWord(storeDetails.daysTillPeriodEnd) }}</span>
								</div>
							</div>
						</div>
					</div>
				</b-navbar>
			</div>
			<div class="col-auto navigation-row_right">
				<div class="navigation-row_right--data">
					<div class="row row-data-items">
						<div class="col-4 data-items data-items_first">
							<div class="data-items--text px-3 w-100">
								<span v-html="$t('nav.yourCashBack')"></span>
								<span class="text-data color-red"> {{ storeDetails.currency === 'GBP' ? '£' : '' }} {{ storeDetails.cashback }} {{ storeDetails.currency === 'EUR' ? '€' : '' }} </span>
							</div>
						</div>
						<div class="col-4 data-items">
							<div class="data-items--text w-100">
								<router-link :to="`/${$i18n.locale}/total-cashback`" class="total-cashback-nav-button pulse-animation" v-html="$t('nav.totalCashBack')"></router-link>
							</div>
						</div>
						<div class="col-4 data-items data-items_second">
							<div class="data-items--icon">
								<img src="@/assets/icons/time.png" srcset="@/assets/icons/time.png 1x, @/assets/icons/time@2x.png 2x" alt />
							</div>
							<div class="data-items--text">
								{{ $t('nav.endStageTime') }}
								<span class="text-data color-red">{{ storeDetails.daysTillPeriodEnd }} {{ daysWord(storeDetails.daysTillPeriodEnd) }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="navigation-row_right--logout-languages">
					<div class="navigation-row_right--logout-languages--store">
						<div class="store-container">{{ storeDetails.storeName }}</div>
					</div>
					<div class="navigation-row_right--logout-languages--buttons">
						<img
							class="language-buttons language-buttons_pl"
							src="@/assets/icons/pl.png"
							srcset="@/assets/icons/pl.png 1x, @/assets/icons/pl@2x.png 2x"
							alt
							@click="switchLocale('pl')"
							:class="$i18n.locale == 'en' ? 'language-buttons-inactive' : ''"
						/>
						<img
							class="language-buttons language-buttons_en"
							src="@/assets/icons/en.png"
							srcset="@/assets/icons/en.png 1x, @/assets/icons/en@2x.png 2x"
							alt
							@click="switchLocale('en')"
							:class="$i18n.locale == 'pl' ? 'language-buttons-inactive' : ''"
						/>
						<img class="logout-button" @click="onLogout" id="logout-desktop" src="@/assets/icons/logout.png" srcset="@/assets/icons/logout.png 1x, @/assets/icons/logout@2x.png 2x" alt />
						<b-tooltip target="logout-desktop" triggers="hover">
							{{ $t('nav.logoutTooltip') }}
						</b-tooltip>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
	data() {
		return {
			isLoading: true,
			headerScrollClass: '',
			progressBarValue: 40,
		};
	},
	async created() {
		try {
			await this.getStoreDetails();
			this.isLoading = false;
			window.addEventListener('scroll', this.handleScroll);
		} catch (error) {
			console.log(error);
		}
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	computed: {
		...mapGetters('auth', {
			auth: 'isAuthenticated',
		}),
		...mapState({
			storeDetails: (state) => state.main.storeDetails,
		}),
	},
	methods: {
		...mapActions('auth', {
			logout: 'logout',
		}),
		...mapActions('main', {
			getStoreDetails: 'getStoreDetails',
		}),
		onLogout() {
			this.logout();
		},
		thousands_separators(num) {
			if (num != null) {
				let num_parts = num.toString().split('.');
				num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
				return num_parts.join('.');
			}
			if (num == null) return;
		},
		handleScroll() {
			if (window.scrollY > 0) return (this.headerScrollClass = 'header-scrolled');
			if (window.scrollY < 100) return (this.headerScrollClass = '');
		},
		daysWord(daysAmount) {
			if (daysAmount == 1) return this.$t('nav.day');
			if (daysAmount != 1) return this.$t('nav.days');
		},
		switchLocale(locale) {
			if (this.$i18n.locale !== locale) {
				this.$i18n.locale = locale;
				const to = this.$router.resolve({ params: { locale } });
				this.$router.push(to.location);
				localStorage.setItem('userLang', locale);
			}
		},
	},
};
</script>
