<template>
	<div class="results">
		<div class="row" v-if="!isResultsLoading">
			<div class="col-12 mb-4">
				<h1 class="page-titles mt-4">{{ $t('pages.totalCashBack.title') }}</h1>
			</div>
			<div class="col-lg-2 pb-5 d-flex justify-content-center">
				<router-link :to="`/${$i18n.locale}/results`" class="select-form-styles results-form-container_total-cashback back-button shadow-style">{{
					$t('pages.totalCashBack.backButtonLabel')
				}}</router-link>
			</div>
			<div class="col-lg-8 table-container">
				<div class="select-form-styles results-form-container_total-cashback total-cashback mb-5">
					{{ $t('pages.results.totalCashback') }}
					<span class="color-red"> {{ totalCashback.currencyCode === 'GBP' ? '£' : '' }} {{ totalCashback.totalCashback }} {{ totalCashback.currencyCode === 'EUR' ? '€' : '' }} </span>
				</div>
				<table class="table-styles">
					<thead>
						<tr>
							<th>{{ $t('pages.totalCashBack.table.stage') }}</th>
							<th>{{ $t('pages.totalCashBack.table.billingData') }}</th>
							<th>{{ $t('pages.totalCashBack.table.cashback') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="cashback in totalCashback.results" :key="cashback.periodId">
							<td>{{ cashback.periodDescription }}</td>
							<td>{{ cashback.periodName }}</td>
							<td v-if="cashback.vpValue">{{ totalCashback.currencyCode === 'GBP' ? '£' : '' }} {{ cashback.vpValue }} {{ totalCashback.currencyCode === 'EUR' ? '€' : '' }}</td>
							<td v-else>-</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-lg-2"></div>
		</div>

		<div v-if="isResultsLoading">
			<Preloader />
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Preloader from './../components/Preloader.vue';

export default {
	name: 'TotalCashback',
	components: {
		Preloader,
	},
	data() {
		return {
			isResultsLoading: true,
		};
	},
	computed: {
		...mapState({
			totalCashback: (state) => state.results.totalCashback,
		}),
	},
	methods: {
		...mapActions('results', {
			getTotalCashback: 'getTotalCashback',
		}),
		thousands_separators(num) {
			if (num != null) {
				let num_parts = num.toString().split('.');
				num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
				return num_parts.join('.');
			}
			if (num == null) return;
		},
	},
	async created() {
		try {
			await this.getTotalCashback();
			this.isResultsLoading = false;
		} catch (error) {
			console.log(error);
		}
	},
};
</script>
<style scoped lang="scss">
.table-container {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.back-button {
	width: fit-content;
}
.total-cashback {
	&:hover {
		cursor: text;
	}
}
</style>
