<template>
	<div>
		<div class="row top-main-images" v-if="$route.path === `/${$i18n.locale}/`">
			<div class="col-lg-12 top-main-images_home">
				<img src="@/assets/logos/logo.png" srcset="@/assets/logos/logo.png 1x, @/assets/logos/logo@2x.png 2x" class="top-main-images_home_logo" alt />
				<img src="@/assets/images/home-side-img.png" srcset="@/assets/images/home-side-img.png 1x, @/assets/images/home-side-img@2x.png 2x" class="top-main-images_home_side-img" alt />
			</div>
		</div>
		<div class="row top-main-images top-main-images_no-homepage" v-if="!isLoading && $route.path !== `/${$i18n.locale}/`">
			<div class="col-lg-6 top-main-images_left">
				<img src="@/assets/logos/logo.png" srcset="@/assets/logos/logo.png 1x, @/assets/logos/logo@2x.png 2x" class="top-main-images_home_logo" alt />
			</div>
			<div class="col-lg-6 top-main-images_right" v-if="groupCode">
				<img src="@/assets/images/top-main-makler.png" srcset="@/assets/images/top-main-makler.png 1x, @/assets/images/top-main-makler@2x.png 2x" alt v-if="groupCode === 'BRO'" />
				<img src="@/assets/images/top-main-bankier.png" srcset="@/assets/images/top-main-bankier.png 1x, @/assets/images/top-main-bankier@2x.png 2x" alt v-if="groupCode === 'BAN'" />
				<img src="@/assets/images/top-main-inwestor.png" srcset="@/assets/images/top-main-inwestor.png 1x, @/assets/images/top-main-inwestor@2x.png 2x" alt v-if="groupCode === 'INV'" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	name: 'PageHeader',
	data() {
		return {
			isLoading: true,
			groupCode: '',
		};
	},
	async created() {
		try {
			await this.getUserGroup();
			this.groupCode = this.userGroup.groupCode || '';
			this.isLoading = false;
		} catch (error) {
			console.log(error);
		}
	},
	computed: {
		...mapState({
			userGroup: (state) => state.users.userGroup,
		}),
	},
	methods: {
		...mapActions('users', {
			getUserGroup: 'getUserGroup',
		}),
		takeFirstLetter(text) {
			if (text !== null) return text.charAt(0);
			if (text === null) return '';
		},
	},
};
</script>
