<template>
	<div>
		<b-modal centered v-model="modalShow" id="modal-component" v-if="modalInfo == 1">
			<div>
				<h1 class="color-secondary text-center page-titles" v-html="$t('modals.stageModalEnd1Title')"></h1>
			</div>
			<div class="stageModal-info lapsus-font" v-html="$t('modals.stageModalEnd1')"></div>
		</b-modal>
		<b-modal centered v-model="modalShow" id="modal-component" v-if="modalInfo == 2">
			<div class="stageModal-info lapsus-font" v-html="$t('modals.stageModalResults1')"></div>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'CustomModal',
	data() {
		return {
			modalShow: false,
			imageModalShow: false,
			modalInfo: 0,
			startModalEndStage1: new Date('Nov 4, 2024 16:00:00').getTime(),
			stopModalEndStage1: new Date('Nov 7, 2024 23:59:59').getTime(),
			startModalResultsStage1: new Date('Nov 8, 2024 00:00:00').getTime(),
			stopModalResultsStage1: new Date('Nov 12, 2024 23:59:59').getTime(),
			actualDate: new Date().getTime(),
		};
	},
	created() {
		if (this.startModalEndStage1 <= this.actualDate && this.stopModalEndStage1 >= this.actualDate) {
			this.modalInfo = 1;
			this.modalShow = true;
		}
		if (this.startModalResultsStage1 <= this.actualDate && this.stopModalResultsStage1 >= this.actualDate) {
			this.modalInfo = 2;
			this.modalShow = true;
		}
	},
};
</script>
