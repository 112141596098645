import axios from 'axios';
import authHeader from '../authHeader';
// import router from '../../router'

const state = {
	agreementsInfo: {},
	userGroup: {},
};

const getters = {};

const actions = {
	saveAgreements: async ({ commit }, agreements) => {
		try {
			const res = await axios.post(`/api/loyalty/agreement`, { agreements }, { headers: authHeader() });

			commit('updateAgreements', res.data);
			if (res.data.success === true) localStorage.setItem('areRequiredAgreementsAccepted', true);
			return res;
		} catch (error) {
			console.log(error);
		}
	},
	getAgreementsInfo: async ({ commit }) => {
		try {
			const res = await axios.get(`/api/loyalty/agreement`, { headers: authHeader() });
			commit('updateAgreementsInfo', res.data);
			return res;
		} catch (error) {
			console.log(error);
		}
	},
	getUser: async ({ commit }) => {
		try {
			const res = await axios.get(`/user/user.php`);
			commit('updateUser', res.data);
		} catch (error) {
			console.log(error);
		}
	},
	getUserGroup: async ({ commit }) => {
		try {
			const res = await axios.get(`/api/loyalty/group`, { headers: authHeader() });
			commit('updateUserGroup', res.data);
		} catch (error) {
			console.log(error);
		}
	},
};

const mutations = {
	updateUser: (state, payload) => {
		state.userProfileData = payload;
	},
	updateAgreementsInfo: (state, payload) => {
		state.agreementsInfo = payload;
	},
	updateAgreements: (state, payload) => {
		state.agreements = payload;
	},
	updateUserGroup: (state, payload) => {
		state.userGroup = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
