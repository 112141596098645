import axios from 'axios'
import authHeader from '../authHeader'

const state = {
  catalogDetails: {}
}

const getters = {}

const actions = {
  getCatalogDetails: async ({ commit }) => {
    try {
      const res = await axios.get(`/api/loyalty/catalog`,
        { headers: authHeader() }
      )
      commit('updateCatalogDetails', res.data)
    } catch (error) {
      console.log(error)
    }
  }
}

const mutations = {
  updateCatalogDetails: (state, payload) => {
    state.catalogDetails = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
