import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import { Config } from './config';
import VueI18n from 'vue-i18n';
import i18n from './i18n';
import VueGtag from 'vue-gtag';

import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

axios.defaults.baseURL = Config.API;

Vue.use(VueI18n);
Vue.use(BootstrapVue);

Vue.use(VueGtag, {
	config: { id: 'G-9MQ9K1TSPZ' },
});

Vue.config.productionTip = false;

new Vue({
	router,
	i18n,
	store,
	render: (h) => h(App),
}).$mount('#app');

document.title = Config.APP_TITLE;
