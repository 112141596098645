import Vue from 'vue';
import Vuex from 'vuex';
import alert from './modules/alert';
import auth from './modules/auth';
import users from './modules/users';
import results from './modules/results';
import period from './modules/period';
import main from './modules/main';
import contact from './modules/contact';
import catalog from './modules/catalog';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		alert,
		auth,
		users,
		results,
		period,
		main,
		contact,
		catalog,
	},
});
