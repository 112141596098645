import axios from 'axios';
import authHeader from '../authHeader';

const state = {
	storeDetails: {},
	pageDefaultDetails: {},
};

const getters = {};

const actions = {
	getStoreDetails: async ({ commit }) => {
		try {
			const res = await axios.get(`/api/loyalty/main`, { headers: authHeader() });
			commit('updateStoreDetails', res.data);
		} catch (error) {
			console.log(error);
		}
	},
	getPageDefaultDetails: async ({ commit }, periodId) => {
		try {
			const res = await axios.post(`/api/loyalty/main/details`, { periodId }, { headers: authHeader() });
			commit('updatePageDefaultDetails', res.data);
		} catch (error) {
			console.log(error);
		}
	},
};

const mutations = {
	updateStoreDetails: (state, payload) => {
		state.storeDetails = payload;
	},
	updatePageDefaultDetails: (state, payload) => {
		state.pageDefaultDetails = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
