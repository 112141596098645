<template>
	<div id="app" class="body-styles" :class="$route.path !== `/${$i18n.locale}/login` && $route.path !== `/${$i18n.locale}/agreements` ? 'bg-main' : 'bg-login-agreements'">
		<Header v-if="$route.path !== `/${$i18n.locale}/login` && $route.path !== `/${$i18n.locale}/agreements`" />
		<div
			:class="$route.path !== `/${$i18n.locale}/login` && $route.path !== `/${$i18n.locale}/agreements` ? ' page-content shadow-style main-containers-style' : ''"
			class="custom-container custom-container-height"
		>
			<PageHeader v-if="$route.path !== `/${$i18n.locale}/login` && $route.path !== `/${$i18n.locale}/agreements`" />
			<router-view />
			<ManufacturersLogos v-if="$route.path !== `/${$i18n.locale}/rules` && $route.path !== `/${$i18n.locale}/` && $route.path !== `/${$i18n.locale}/contact`" />
		</div>
		<Footer />
	</div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
import ManufacturersLogos from './components/ManufacturersLogos.vue';
import PageHeader from './components/PageHeader.vue';
export default {
	name: 'app',
	components: {
		Header,
		Footer,
		PageHeader,
		ManufacturersLogos,
	},
};
</script>

<style lang="scss">
#nav {
}
</style>
