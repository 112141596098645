<template>
	<div v-if="!isLoading" class="agreements">
		<div class="row">
			<div class="col-12">
				<div class="logo-container">
					<img src="@/assets/logos/logo.png" srcset="@/assets/logos/logo.png 1x, @/assets/logos/logo@2x.png 2x" alt />
				</div>
			</div>
			<div class="col-12 mt-5">
				<b-form-group class="agreements-form" v-slot="{ ariaDescribedby }">
					<b-form-checkbox-group class="agreements-checkbox-container  main-containers-style shadow-style" id="checkbox-group-2" :aria-describedby="ariaDescribedby">
						<template v-for="agreement in agreementsInfo.agreements">
							<div :key="agreement.id">
								<b-form-checkbox v-model="checkedAgreements" :value="agreement.id" :checked="agreement.isAccepted">
									<span class="" v-if="$i18n.locale == 'pl'"
										>{{ agreement.name }}
										<img
											@click="$i18n.locale == 'pl' ? showModal(agreement.description) : showModal(agreement.engDescription)"
											class="modal-info-button"
											src="@/assets/icons/info.png"
											srcset="@/assets/icons/info.png 1x, @/assets/icons/info@2x.png 2x"
											alt
											v-if="agreement.description != null || agreement.engDescription != null"
									/></span>
									<span class="" v-if="$i18n.locale == 'en'"
										>{{ agreement.engName }}
										<img
											@click="$i18n.locale == 'pl' ? showModal(agreement.description) : showModal(agreement.engDescription)"
											class="modal-info-button"
											src="@/assets/icons/info.png"
											srcset="@/assets/icons/info.png 1x, @/assets/icons/info@2x.png 2x"
											alt
											v-if="agreement.description != null || agreement.engDescription != null"
									/></span>
								</b-form-checkbox>
							</div>
						</template>
					</b-form-checkbox-group>
					<div class="buttons-agreement-row">
						<button class="buttons buttons-primary" @click="onAcceptAgreements">
							{{ $t('pages.agreements.form.buttonForwardLabel') }}
						</button>

						<button tag="button" class="buttons buttons-secondary mt-3" @click="onLogout">
							{{ $t('pages.agreements.form.buttonBackwardLabel') }}
						</button>
					</div>
				</b-form-group>
			</div>
			<!-- <div class="col-12">
        <div class="validation-container"> -->
			<b-alert class="alert-danger-msg validation-error" v-if="showValidation" v-model="showValidation" variant="danger" dismissible>
				{{ validationMsg }}
			</b-alert>
			<!-- </div>
      </div> -->
		</div>

		<b-modal id="modal-center" ref="info-modal" centered scrollable hide-footer>
			<div class="d-block modal-body-styles" v-html="modalAgreementDescription"></div>
		</b-modal>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	data() {
		return {
			showValidation: false,
			validationMsg: '',
			isSubmitting: false,
			isLoading: true,
			checkedAgreements: [],
			modalAgreementDescription: '',
		};
	},
	async created() {
		try {
			const agreementsInfoResponse = await this.getAgreementsInfo();

			if (agreementsInfoResponse.data) {
				this.isLoading = false;
			}
		} catch (error) {
			console.log(error);
		}
	},
	computed: {
		...mapState({
			agreementsInfo: (state) => state.users.agreementsInfo,
		}),
	},
	methods: {
		...mapActions('users', {
			getAgreementsInfo: 'getAgreementsInfo',
			saveAgreements: 'saveAgreements',
		}),
		showModal(agreementDescription) {
			this.$refs['info-modal'].show();
			this.modalAgreementDescription = agreementDescription;
		},
		...mapActions('auth', {
			logout: 'logout',
		}),
		onLogout() {
			this.logout();
		},
		onAcceptAgreements() {
			this.showValidation = false;
			let allRequiredAgreementsChecked = true;

			const agreementsParams = this.agreementsInfo.agreements.map((agreement) => {
				const isAccepted = this.checkedAgreements.includes(agreement.id);
				if (agreement.isRequired && !isAccepted) {
					allRequiredAgreementsChecked = false;
				}
				return {
					id: agreement.id,
					isAccepted,
				};
			});

			if (!allRequiredAgreementsChecked) {
				this.showValidation = true;
				this.validationMsg = this.$t('alerts.agreementsAlert');
				this.isSubmitting = false;
				return;
			}

			this.isSubmitting = true;
			this.saveAgreements(agreementsParams).then(() => {
				this.isSubmitting = false;
				this.$router.push({ path: `/${this.$i18n.locale}/` });
			});
		},
	},
};
</script>
