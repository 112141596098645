<template>
	<div class="bottom-logos-container">
		<div class="row logos-container" :class="$route.path === `/${$i18n.locale}/login` || $route.path === `/${$i18n.locale}/agreements` ? 'main-containers-style shadow-style' : ''">
			<div v-for="(logo, index) in logos" :key="index" class="col logo-item">
				<img :src="logo" />
			</div>
		</div>
	</div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';
import axios from 'axios';
export default {
	name: 'ManufacturersLogos',
	data() {
		return {
			isLoading: true,
			groupCode: '',
			logos: [],
			folder: '10',
			// catalogFilesAddress: 'https://moneymasters24.tcreate.pl',
			// catalogFilesAddress: 'localhost:8080',
			catalogFilesAddress: 'https://moneymasters.fun',
		};
	},
	async created() {
		try {
			// const manufacturersResponse = await this.getManufacturers();
			// if (manufacturersResponse.data) {
			// 	this.isLoading = false;
			// }

			const manufacturersResponse = await axios.get(`${this.catalogFilesAddress}/logos.php?folder=${this.folder}`);
			if (manufacturersResponse.data.logos) {
				for (let index = 0; index < manufacturersResponse.data.logos; index++) {
					this.logos.push(`${this.catalogFilesAddress}/manufacturers/${this.folder}/${index + 1}.png`);
				}
			}
		} catch (error) {
			console.log(error);
		}
	},
	computed: {
		// ...mapState({
		// 	manufacturers: (state) => state.manufacturers,
		// }),
	},
	methods: {
		// ...mapActions('manufacturers', {
		// 	getManufacturers: 'getManufacturers',
		// }),
	},
};
</script>
