<template>
	<div>
		<b-modal centered v-model="imageModalShow" id="image-modal">
			<img v-if="$i18n.locale === 'pl'" class="banner-img" src="@/assets/banners/banner_pl.jpg" alt />
			<img v-if="$i18n.locale === 'en'" class="banner-img" src="@/assets/banners/banner_en.jpg" alt />
			<!-- <img class="banner-img" :src="`${this.filesAddress}/banners/${image}`" alt /> -->
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'PageHeader',
	data() {
		return {
			modalShow: false,
			imageModalShow: false,
			startImageModal: new Date('Oct 21, 2024 00:00:00').getTime(),
			stopImageModal: new Date('Oct 27, 2024 23:59:59').getTime(),
			actualDate: new Date().getTime(),
			// image: 'booster1.png',
			// images: ['booster0.png', 'booster1.png'],
			currentImageName: '',
			currentImageNumber: 0,
			filesAddress: 'https://moneymasters.fun',
		};
	},
	created() {
		if (this.startImageModal <= this.actualDate && this.stopImageModal >= this.actualDate) {
			this.imageModalShow = true;
			// this.changeModalImage();
		}
	},
	methods: {
		// changeModalImage() {
		// 	let i = 0;
		// 	setInterval(() => {
		// 		this.image = this.images[i];
		// 		if (++i === this.images.length) i = 0;
		// 	}, 6000);
		// },
	},
};
</script>
<style scoped></style>
