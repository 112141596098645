<template>
	<div class="">
		<div class="row">
			<div class="col-12 px-0">
				<div class="logo-container">
					<img src="@/assets/logos/logo.png" srcset="@/assets/logos/logo.png 1x, @/assets/logos/logo@2x.png 2x" alt />
				</div>
			</div>

			<div class="col-12 form-container">
				<form @submit.prevent="onSubmit" class="forms-styles forms-styles-login">
					<div class="input mb-4">
						<input type="text" id="username" class="mb-4" v-model="username" :placeholder="$t('pages.login.form.loginPlaceholder')" />
						<input type="password" id="password" v-model="password" :placeholder="$t('pages.login.form.passwordPlaceholder')" />
					</div>

					<div class="language-container mb-3">
						<img
							class="mr-1 language-buttons language-buttons_pl"
							src="@/assets/icons/pl.png"
							srcset="@/assets/icons/pl.png 1x, @/assets/icons/pl@2x.png 2x"
							alt
							@click="switchLocale('pl')"
							:class="$i18n.locale == 'en' ? 'language-buttons-inactive' : ''"
						/>
						<img
							class="ml-1 language-buttons language-buttons_en"
							src="@/assets/icons/en.png"
							srcset="@/assets/icons/en.png 1x, @/assets/icons/en@2x.png 2x"
							alt
							@click="switchLocale('en')"
							:class="$i18n.locale == 'pl' ? 'language-buttons-inactive' : ''"
						/>
					</div>
					<div class="submit">
						<button class="buttons buttons-primary" type="submit">{{ $t('pages.login.form.buttonLabel') }}</button>
					</div>
				</form>
			</div>
		</div>
		<b-alert class="alert-danger-msg validation-error" variant="danger" :show="alert.message !== null" @dismissed="alert.message = null" dismissible>
			{{ $t('alerts.loginAlert') }}
		</b-alert>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	data() {
		return {
			username: '',
			password: '',
			showValidation: false,
			validationMsg: '',
		};
	},
	computed: {
		...mapState({
			alert: (state) => state.alert,
		}),
	},
	methods: {
		...mapActions('auth', {
			login: 'login',
		}),
		onSubmit() {
			const formData = {
				username: this.username,
				password: this.password,
				appCode: 'app3',
			};
			this.login({ username: formData.username, password: formData.password, appCode: formData.appCode });
		},
		switchLocale(locale) {
			if (this.$i18n.locale !== locale) {
				this.$i18n.locale = locale;
				const to = this.$router.resolve({ params: { locale } });
				this.$router.push(to.location);
				localStorage.setItem('userLang', locale);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.alert {
	display: block;
	padding: 15px;
	border: 1px solid;
	&.success {
		color: green;
		border-color: green;
	}
	&.error {
		color: red;
		border-color: red;
	}
}
</style>
