<template>
	<div class="contact">
		<div v-if="isLoading">
			<Preloader />
		</div>
		<div v-if="!isLoading">
			<div class="row">
				<div class="col-12">
					<h1 class="page-titles mt-4">{{ $t('pages.contact.title') }}</h1>
				</div>
			</div>
			<div class="row my-4">
				<div class="col-12 contact-subtitle">{{ $t('pages.contact.subtitle') }}</div>
			</div>
			<div class="row contact-row-container-1">
				<div class="col-md-3 contact-row-container-1_item contact-row-container-1_item--left">
					<img src="@/assets/images/contact-arrow-left.png" srcset="@/assets/images/contact-arrow-left.png 1x, @/assets/images/contact-arrow-left@2x.png 2x" alt />
				</div>
				<div class="col-md-6 contact-row-container-1_item contact-row-container-1_item--center">
					<div class="box-dashed box-dashed_primary">{{ contactDetails.firstname }} {{ contactDetails.lastname }}</div>
				</div>
				<div class="col-md-3 contact-row-container-1_item contact-row-container-1_item--right">
					<img src="@/assets/images/contact-arrow-right.png" srcset="@/assets/images/contact-arrow-right.png 1x, @/assets/images/contact-arrow-right@2x.png 2x" alt />
				</div>
			</div>

			<div class="row contact-row-container-2">
				<div class="col-md-7 contact-row-container-2_item contact-row-container-2_item--left">
					<img src="@/assets/icons/contact-mail.png" srcset="@/assets/icons/contact-mail.png 1x, @/assets/icons/contact-mail@2x.png 2x" alt />

					<div class="box-dashed box-dashed_secondary">{{ contactDetails.email }}</div>
				</div>
				<div class="col-md-5 contact-row-container-2_item contact-row-container-2_item--right">
					<img src="@/assets/icons/contact-phone.png" srcset="@/assets/icons/contact-phone.png 1x, @/assets/icons/contact-phone@2x.png 2x" alt />

					<div class="box-dashed box-dashed_secondary">{{ contactDetails.phoneNumber }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Preloader from './../components/Preloader.vue';

export default {
	name: 'contact',
	components: {
		Preloader,
	},
	data() {
		return {
			isLoading: true,
			phoneNumber: '',
		};
	},
	async created() {
		try {
			await this.getContactDetails();
			this.isLoading = false;
		} catch (error) {
			console.log(error);
		}
	},
	computed: {
		...mapState({
			contactDetails: (state) => state.contact.contactDetails,
		}),
	},
	methods: {
		...mapActions('contact', {
			getContactDetails: 'getContactDetails',
		}),
	},
};
</script>
