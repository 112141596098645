<template>
	<div>
		<!-- link for preloader settings generator https://vue-preloader.netlify.app/ -->
		<loader :object="primaryColor" :size="size" :speed="speed" :bg="backgroundColor" :opacity="opacity" :disableScrolling="disableScrolling" :name="style"></loader>
	</div>
</template>

<script>
import { loader } from 'vue-ui-preloader';

export default {
	data() {
		return {
			primaryColor: '#0042c5',
			size: '5', //px
			speed: '1', //s
			backgroundColor: '#0042c5',
			opacity: '0',
			disableScrolling: 'true',
			style: 'dots',
		};
	},
	components: {
		loader,
	},
};
</script>
